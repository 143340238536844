
import Vue from "vue";
import moment from "moment";

//class
import { DailyWorkReportKey } from "@/models/dailyWorkReport/DailyWorkReportKey";
import {
  Hakuen,
  HakuenText,
  HakuenTable,
  HakuenTableHeaders
} from "@/models/dailyWorkReport/Hakuen";
import { CodeKbnList } from "@/models/codeMaster/Code";
import {
  PulldownResponse,
  codeKbnFilter
} from "@/models/response/PulldownResponse";
import {
  CheckboxGroupItems,
  pulldownToCheckItems
} from "@/components/input/CheckboxGroup.vue";

// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";

// Components
import { Headers } from "@/components/input/DailyWorkReportInput.vue";
import DailyWorkReportFormDialog from "@/components/dialog/DailyWorkReportFormDialog.vue";
import DataIterators from "@/components/table/dailyworkreport/dataiterators/DataIterators.vue";
import Snackbar from "@/components/dialog/Snackbar.vue";
import Overlay from "@/components/dialog/Overlay.vue";
import Shain from "@/models/Shain";
import { ShainSearchRequest } from "@/models/ShainSearchRequest";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";
import { DATE_FORMAT } from "@/defines";
import VulcanizingLinkageDialog from "@/components/dialog/linkage/VulcanizingLinkageDialog.vue";
import { VulcanizingLinkageResponse } from "@/models/dailyWorkReport/response/VulcanizingLinkageResponse";

const DATE = moment().format(DATE_FORMAT.DATE);

export default Vue.extend({
  name: "Test",
  mixins: [Common, ShowDialogs, Api],
  components: {
    VulcanizingLinkageDialog
  },
  props: {
    date: {
      type: String,
      required: false,
      default: DATE
    }
  },
  data() {
    return {
      isSearchDialog: false,
      snackbar: false,
      snackbarText: "",
      selectDate: this.date,
      isAdd: false,
      isOpen: false,
      maxRecord: 30,
      tableHeaders: HakuenTableHeaders,
      editRecord: new Hakuen(new DailyWorkReportKey({ workDate: DATE })),
      reportData: [] as Hakuen[], //テーブルデータ
      title: "剥鉛作業記録",
      isLoading: false,
      codeGroup: [] as PulldownResponse[],
      userItems: [] as CheckboxGroupItems[], //作業者
      machineCodeItems: [] as CheckboxGroupItems[] //撚線機械名
    };
  },
  computed: {
    headers() {
      return [
        {
          ...HakuenText.machineCode,
          type: "select",
          items: this.machineCodeItems,
          default: "1"
        },
        {
          value: {
            key: HakuenText.worker.value,
            other: HakuenText.biko.value
          },
          text: HakuenText.worker.text,
          type: "combobox",
          items: this.userItems
        },
        { ...HakuenText.inspectionFlag, type: "checkbox" },
        {
          ...HakuenText.acceptNo,
          type: "text",
          maxLength: 40,
          default: "20本取り機"
        },
        { ...HakuenText.successFlag, type: "checkbox" },
        { ...HakuenText.preProcessDrum, type: "text", maxLength: 40 },
        { ...HakuenText.specificationNo, type: "text", maxLength: 40 },
        { ...HakuenText.itemName, type: "text", maxLength: 80 },
        { ...HakuenText.size, type: "text", maxLength: 80 },
        {
          ...HakuenText.length,
          type: "number",
          digit: 10,
          scale: 2,
          default: 0.32
        },
        { ...HakuenText.suryoMeisai, type: "text", maxLength: 80 },
        { ...HakuenText.windingDrum, type: "text", maxLength: 40 },
        { ...HakuenText.abnormality, type: "text", maxLength: 255 },
        { ...HakuenText.biko, type: "text", maxLength: 255 }
      ] as Headers[];
    },
    tableRecord() {
      const records: Hakuen[] = this.reportData;
      const result = records.map((h: Hakuen) => {
        const record = new HakuenTable(h);
        const date = moment(h.updateTs).format(DATE_FORMAT.DATE_MINUTE);
        record.updateTs = date;
        return record;
      });
      return result;
    }
  },
  methods: {
    onLink(value: VulcanizingLinkageResponse) {
      console.log(value);
    },
    /**
     * 追加
     */
    onAdd() {
      console.log("add");
      this.isAdd = true;
      if (this.reportData.length >= this.maxRecord) {
        this.snackbarText = `${this.maxRecord}列以上は登録できません。`;
        this.snackbar = true;
        return;
      }
      const reocrdKey = new DailyWorkReportKey({ workDate: this.selectDate });
      this.editRecord = new Hakuen(reocrdKey);
      this.isOpen = true;
    },
    /**
     * 編集
     * @param value index
     */
    onEdit(value: number) {
      console.log("edit");
      this.isAdd = false;
      this.editRecord = Object.assign({}, this.reportData[value]);
      this.isOpen = true;
    },
    /**
     * コピー
     * @param value DailyWorkReportSearchResponse
     */
    async onCopy(value: DailyWorkReportSearchResponse, close: () => void) {
      console.log("copy", value);
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        const api = new Api();
        const param = new DailyWorkReportKey(value);
        const result = await api.$post<Hakuen>(
          api.Paths.dailyWorkReport.hakuen.searchKey,
          param
        );
        result.workDate = this.selectDate;
        this.editRecord = result;
        close();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("copy end");
        this.isLoading = false;
      }
    },
    // ==============================
    // 検索
    // ==============================
    async onSearch() {
      console.log("onSearch start");
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        this.reportData = await this.getHakuenRecord();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onSearch end");
        this.isLoading = false;
      }
    },
    // ==============================
    // 保存
    // ==============================
    async onSave(saveValue: Hakuen) {
      console.log("onSave start");
      const showDialogs = new ShowDialogs();
      const api = new Api();

      this.isLoading = true;
      try {
        if (this.isAdd) {
          await api.$post(api.Paths.dailyWorkReport.hakuen.insert, saveValue);
        } else {
          await api.$post(api.Paths.dailyWorkReport.hakuen.update, saveValue);
        }
        showDialogs.$info("更新しました。", "更新");
        this.reportData = await this.getHakuenRecord();
        this.isOpen = false;
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onSave end");
        this.isLoading = false;
      }
    },
    /**
     * 削除
     * @param index
     */
    async onDelete(index: number) {
      console.log("onDelete start", index);
      const api = new Api();
      const showDialogs = new ShowDialogs();
      const title = "確認";
      const message = `${index +
        1}列目の作業記録を削除します。\nよろしいですか？`;
      const confirm = await showDialogs.$deleteConfirm(message, title);
      if (!confirm) return;

      this.isLoading = true;
      try {
        const target = this.reportData[index];
        await api.$post(api.Paths.dailyWorkReport.hakuen.delete, target);
        showDialogs.$info("削除しました。", "情報");
        this.reportData = await this.getHakuenRecord();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onDelete end");
        this.isLoading = false;
      }
    },
    /**
     * 剥鉛作業記録データ取得
     */
    async getHakuenRecord() {
      const api = new Api();
      const param = { workDate: new Date(this.selectDate) };
      const result = await api.$post<Hakuen[]>(
        api.Paths.dailyWorkReport.hakuen.search,
        param
      );
      return result;
    },
    /**
     * データベースから選択項目データ取得
     */
    async getItems() {
      console.log("getItems start");
      const showDialogs = new ShowDialogs();
      const api = new Api();
      this.isLoading = true;
      try {
        //apiパス
        const userPath = api.Paths.shainSearch;
        const codeMasterPath = api.Paths.codeMasterPulldown;
        const codeGroupPath = api.Paths.codeGroupPulldown;

        //リクエスト
        const shainRequest = new ShainSearchRequest({
          department: [this.$store.state.dailyWorkReportStore.nippoType],
          isValid: "1"
        });
        const userPromise = api.$post<Shain[]>(userPath, shainRequest);
        const codeMasterPromise = api.$get<PulldownResponse[]>(codeMasterPath);
        const codeGroupPromise = api.$get<PulldownResponse[]>(codeGroupPath);
        const [user, codeMaster, codeGroup] = await Promise.all([
          userPromise,
          codeMasterPromise,
          codeGroupPromise
        ]);

        this.codeGroup = codeGroup;
        //ユーザデータ取得
        this.userItems = user.map(e => ({ value: e.userId, text: e.userName }));
        //コードマスタのデータをすべて取得
        //剥鉛機械名
        const machine = codeKbnFilter(codeMaster, CodeKbnList.HAKUEN);
        this.machineCodeItems = pulldownToCheckItems(machine);
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("getItems end");
        this.isLoading = false;
      }
    }
  },
  async created() {
    this.$store.commit("dailyWorkReportStore/nippoTypeSet", "4");
    await this.getItems();
    await this.onSearch();
  }
});
